import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import getCatalogPanels from '@/api/getCatalogPanels'
import getSummaryOfAllCustomPanelsOfUser from '@/api/getSummaryOfAllCustomPanelsOfUser'
import getReferenceGenomes from '@/api/getReferenceGenomes'
import getAdminGenomesFiles from '@/api/getAdminGenomesFiles'
import deletePanel from '@/api/deletePanel'
import getAdminPanels from '@/api/getAdminPanels'
import deleteAdminPanel from '@/api/deleteAdminPanel'
import {
  DNAPanel,
  GenomeReferenceFiles,
  PanelsState,
  ProteinPanel,
  RootState
} from '@/types'

const state: PanelsState = {
  dnaPanels: [],
  customGenomes: [],
  proteinPanels: [
    {
      assay_type: 'Protein',
      can_order: false,
      can_submit: false,
      date_last_modified: '2020-10-06 05:41:37',
      est_num_amplicons: 9999,
      has_datasheet: true,
      hgs_expiry: 9999,
      hgsid: '9999',
      lineitems: [
        {
          description: 'TotalSeqTM-D0062 anti-human CD10',
          name: 'Hu.CD10',
          id: 'HI10a',
          sequence: 'CAGCCATTCATTAGG'
        },
        {
          description: 'TotalSeqTM-D0061 anti-human CD117 (c-kit)',
          name: 'Hu.CD117',
          id: '104D2',
          sequence: 'AGACTAATAGCTGAC'
        },
        {
          description: 'TotalSeqTM-D0161 anti-human CD11b',
          name: 'Hu.CD11b',
          id: 'ICRF44',
          sequence: 'GACAAGTGATCTGCA'
        },
        {
          description: 'TotalSeqTM-D0053 anti-human CD11c',
          name: 'Hu.CD11c',
          id: 'S-HCL-3',
          sequence: 'TACGCCTATAACTTG'
        },
        {
          description: 'TotalSeqTM-D0064 anti-human CD123',
          name: 'Hu.CD123',
          id: '6H6',
          sequence: 'CTTCACTCTGTCAGG'
        },
        {
          description: 'TotalSeqTM-D0364 anti-human CD13',
          name: 'Hu.CD13',
          id: 'WM15',
          sequence: 'TTTCAACGCCCTTTC'
        },
        {
          description: 'TotalSeqTM-D0055 anti-human CD138 (Syndecan-1)',
          name: 'Hu.CD138',
          id: 'MI15',
          sequence: 'ACTCTTTCGTTTACG'
        },
        {
          description: 'TotalSeqTM-D0081 anti-human CD14',
          name: 'Hu.CD14',
          id: 'M5E2',
          sequence: 'TCTCAGACCTCCGTA'
        },
        {
          description: 'TotalSeqTM-D0163 anti-human CD141 (Thrombomodulin)',
          name: 'Hu.CD141',
          id: 'M80',
          sequence: 'GGATAACCGCGCTTT'
        },
        {
          description: 'TotalSeqTM-D0083 anti-human CD16',
          name: 'Hu.CD16',
          id: '3G8',
          sequence: 'AAGTTCACTCTTTGC'
        },
        {
          description: 'TotalSeqTM-D0358 anti-human CD163',
          name: 'Hu.CD163',
          id: 'GHI/61',
          sequence: 'GCTTCTCCTTCCTTA'
        },
        {
          description: 'TotalSeqTM-D0050 anti-human CD19',
          name: 'Hu.CD19',
          id: 'HIB19',
          sequence: 'CTGGGCAATTACTCG'
        },
        {
          description: 'TotalSeqTM-D0160 anti-human CD1c',
          name: 'Hu.CD1c',
          id: 'L161',
          sequence: 'GAGCTACTTCACTCG'
        },
        {
          description: 'TotalSeqTM-D0367 anti-human CD2',
          name: 'Hu.CD2',
          id: 'TS1/8',
          sequence: 'TACGATTTGTCAGGG'
        },
        {
          description: 'TotalSeqTM-D0393 anti-human CD22',
          name: 'Hu.CD22',
          id: 'S-HCL-1',
          sequence: 'GGGTTGTTGTCTTTG'
        },
        {
          description: 'TotalSeqTM-D0085 anti-human CD25',
          name: 'Hu.CD25',
          id: 'BC96',
          sequence: 'TTTGTCCTGTACGCC'
        },
        {
          description: 'TotalSeqTM-D0034 anti-human CD3',
          name: 'Hu.CD3',
          id: 'UCHT1',
          sequence: 'CTCATTGTAACTCCT'
        },
        {
          description: 'TotalSeqTM-D0028 anti-human CD30',
          name: 'Hu.CD30',
          id: 'BY88',
          sequence: 'TCAGGGTGTGCTGTA'
        },
        {
          description: 'TotalSeqTM-D0370 anti-human CD303 (BDCA-2)',
          name: 'Hu.CD303',
          id: '201A',
          sequence: 'GAGATGTCCGAATTT'
        },
        {
          description: 'TotalSeqTM-D0406 anti-human CD304 (Neuropilin-1)',
          name: 'Hu.CD304',
          id: '12C2',
          sequence: 'GGACTAAGTTTCGTT'
        },
        {
          description: 'TotalSeqTM-D0052 anti-human CD33',
          name: 'Hu.CD33',
          id: 'P67.6',
          sequence: 'TAACTCAGGGCCTAT'
        },
        {
          description: 'TotalSeqTM-D0054 anti-human CD34',
          name: 'Hu.CD34',
          id: '581',
          sequence: 'GCAGAAATCTCCCTT'
        },
        {
          description: 'TotalSeqTM-D0389 anti-human CD38',
          name: 'Hu.CD38',
          id: 'HIT2',
          sequence: 'TGTACCCGCTTGTGA'
        },
        {
          description: 'TotalSeqTM-D0072 anti-human CD4',
          name: 'Hu.CD4',
          id: 'RPA-T4',
          sequence: 'TGTTCCCGCTCAACT'
        },
        {
          description: 'TotalSeqTM-D0048 anti-human CD45',
          name: 'Hu.CD45',
          id: '2D1',
          sequence: 'TCCCTTGCGATTTAC'
        },
        {
          description: 'TotalSeqTM-D0063 anti-human CD45RA',
          name: 'Hu.CD45RA',
          id: 'HI100',
          sequence: 'TCAATCCTTCCGCTT'
        },
        {
          description: 'TotalSeqTM-D0087 anti-human CD45RO',
          name: 'Hu.CD45RO',
          id: 'UCHL1',
          sequence: 'CTCCGAATCATGTTG'
        },
        {
          description: 'TotalSeqTM-D0576 anti-human CD49d',
          name: 'Hu.CD49d',
          id: '9F10',
          sequence: 'CCATTCAACTTCCGG'
        },
        {
          description: 'TotalSeqTM-D0138 anti-human CD5',
          name: 'Hu.CD5',
          id: 'UCHT2',
          sequence: 'CATTAACGGGATGCC'
        },
        {
          description: 'TotalSeqTM-D0047 anti-human CD56',
          name: 'Hu.CD56',
          id: '5.1H11',
          sequence: 'TCCTTTCCTGATAGG'
        },
        {
          description: 'TotalSeqTM-D0147 anti-human CD62L',
          name: 'Hu.CD62L',
          id: 'DREG-56',
          sequence: 'GTCCCTGCAACTTGA'
        },
        {
          description: 'TotalSeqTM-D0218 anti-human CD62P (P-Selectin)',
          name: 'Hu.CD62P',
          id: 'AK4',
          sequence: 'CCTTCCGTATCCCTT'
        },
        {
          description: 'TotalSeqTM-D0162 anti-human CD64',
          name: 'Hu.CD64',
          id: '10.1',
          sequence: 'AAGTATGCCCTACGA'
        },
        {
          description: 'TotalSeqTM-D0146 anti-human CD69',
          name: 'Hu.CD69',
          id: 'FN50',
          sequence: 'GTCTCTTGGCTTAAA'
        },
        {
          description: 'TotalSeqTM-D0066 anti-human CD7',
          name: 'Hu.CD7',
          id: 'CD7-6B7',
          sequence: 'TGGATTCCCGGACTT'
        },
        {
          description: 'TotalSeqTM-D0394 anti-human CD71',
          name: 'Hu.CD71',
          id: 'CY1G4',
          sequence: 'CCGTGTTCCTCATTA'
        },
        {
          description: 'TotalSeqTM-D0046 anti-human CD8',
          name: 'Hu.CD8',
          id: 'SK1',
          sequence: 'GCGCAACTTGATGAT'
        },
        {
          description: 'TotalSeqTM-D0359 anti-human CD83',
          name: 'Hu.CD83',
          id: 'HB15e',
          sequence: 'CCACTCATTTCCGGT'
        },
        {
          description: 'TotalSeqTM-D0060 anti-human CD90 (Thy1)',
          name: 'Hu.CD90',
          id: '5E10',
          sequence: 'GCATTGTACGATTCA'
        },
        {
          description: 'TotalSeqTM-D0352 anti-human FceRIa',
          name: 'Hu.FceRIa',
          id: 'AER-37 (CRA-1)',
          sequence: 'CTCGTTTCCGTATCG'
        },
        {
          description: 'TotalSeqTM-D0159 anti-human HLA-DR',
          name: 'Hu.HLA.DR',
          id: 'L243',
          sequence: 'AATAGCGAGCAAGTA'
        },
        {
          description: 'TotalSeqTM-D0073 anti-mouse/human CD44',
          name: 'HuMs.CD44',
          id: 'IM7',
          sequence: 'TGGCTTCAGGTCCTA'
        },
        {
          description: 'TotalSeqTM-D0090 Mouse IgG1-K',
          name: 'Isotype_IgG1',
          id: 'MOPC-21',
          sequence: 'GCCGGACGACATTAA'
        },
        {
          description: 'TotalSeqTM-D0091 Mouse IgG2a-K',
          name: 'Isotype_IgG2a',
          id: 'MOPC-173',
          sequence: 'CTCCTACCTAAACTG'
        },
        {
          description: 'TotalSeqTM-D0092 Mouse IgG2b-K',
          name: 'Isotype_IgG2b',
          id: 'MPC-11',
          sequence: 'ATATGTATCACGCGA'
        }
      ],
      panel_id: 1009999,
      panel_description:
        'Add another dimension to your single-cell DNA genotyping data by using the TotalSeq™-D Heme Oncology Cocktail. This cocktail of 45 lyophilized and pre-titrated antibodies works in tandem with Mission Bio’s single-cell multi-omics Tapestri Platform, enabling the generation of both genotype and immunophenotype data. To inquire about custom oligo-conjugated antibodies contact a Mission Bio representative for more information.',
      panel_label: 'totalseq-d-heme-oncology',
      panel_name: 'TotalSeq™-D Heme Oncology Cocktail',
      panel_size: 9999,
      panel_status: 'Ready',
      panel_type: 'Catalog',
      panel_source: 'Wet Lab-Tested',
      pct_coverage: 100,
      num_antibodies: 45,
      num_amplicons: 0,
      user_id: 1,
      num_orders: 0,
      num_lineitems: 9999,
      total_target_size: 9999,
      s3_results_dir: '',
      validated: true,
      genome_id: -1,
      review: true,
      virtual_panel: false,
      ready_to_order: true,
      download_location:
        'https://dl.missionbio.io/data/protein-panels/totalseq-d-heme-oncology.csv'
    },
    {
      assay_type: 'Protein',
      can_order: false,
      can_submit: false,
      date_last_modified: '2023-08-31 00:00:00',
      est_num_amplicons: 9999,
      has_datasheet: true,
      hgs_expiry: 9999,
      hgsid: '9999',
      lineitems: [
        {
          name: 'Hu.CD2',
          id: 'TS1/8',
          description: 'TotalSeq™-D0367 anti-human CD2',
          sequence: 'TACGATTTGTCAGGG'
        },
        {
          name: 'Hu.CD3',
          id: 'UCHT1',
          description: 'TotalSeq™-D0034 anti-human CD3',
          sequence: 'CTCATTGTAACTCCT'
        },
        {
          name: 'Hu.CD7',
          id: 'CD7-6B7',
          description: 'TotalSeq™-D0066 anti-human CD7',
          sequence: 'TGGATTCCCGGACTT'
        },
        {
          name: 'Hu.CD10',
          id: 'HI10a',
          description: 'TotalSeq™-D0062 anti-human CD10',
          sequence: 'CAGCCATTCATTAGG'
        },
        {
          name: 'Hu.CD11b',
          id: 'ICRF44',
          description: 'TotalSeq™-D0161 anti-human CD11b',
          sequence: 'GACAAGTGATCTGCA'
        },
        {
          name: 'Hu.CD13',
          id: 'WM15',
          description: 'TotalSeq™-D0364 anti-human CD13',
          sequence: 'TTTCAACGCCCTTTC'
        },
        {
          name: 'Hu.CD14',
          id: 'M5E2',
          description: 'TotalSeq™-D0081 anti-human CD14',
          sequence: 'TCTCAGACCTCCGTA'
        },
        {
          name: 'Hu.CD19',
          id: 'HIB19',
          description: 'TotalSeq™-D0050 anti-human CD19',
          sequence: 'CTGGGCAATTACTCG'
        },
        {
          name: 'Hu.CD22',
          id: 'S-HCL-1',
          description: 'TotalSeqT™-D0393 anti-human CD22',
          sequence: 'GGGTTGTTGTCTTTG'
        },
        {
          name: 'Hu.CD33',
          id: 'P67.6',
          description: 'TotalSeq™-D0052 anti-human CD33',
          sequence: 'TAACTCAGGGCCTAT'
        },
        {
          name: 'Hu.CD34',
          id: '581',
          description: 'TotalSeq™-D0054 anti-human CD34',
          sequence: 'GCAGAAATCTCCCTT'
        },
        {
          name: 'Hu.CD38',
          id: 'HIT2',
          description: 'TotalSeq™-D0389 anti-human CD38',
          sequence: 'TGTACCCGCTTGTGA'
        },
        {
          name: 'Hu.CD45RA',
          id: 'HI100',
          description: 'TotalSeq™-D0063 anti-human CD45RA',
          sequence: 'TCAATCCTTCCGCTT'
        },
        {
          name: 'Hu.CD56',
          id: '5.1H11',
          description: 'TotalSeq™-D0047 anti-human CD56',
          sequence: 'TCCTTTCCTGATAGG'
        },
        {
          name: 'Hu.CD123',
          id: '6H6',
          description: 'TotalSeq™-D0064 anti-human CD123',
          sequence: 'CTTCACTCTGTCAGG'
        },
        {
          name: 'Hu.HLA.DR',
          id: 'L243',
          description: 'TotalSeq™-D0159 anti-human HLA-DR',
          sequence: 'AATAGCGAGCAAGTA'
        },
        {
          name: 'Hu.CD117',
          id: 'A3C6E2',
          description: 'TotalSeq™-D1260 anti-human CD117 (c-kit)',
          sequence: 'GTAACTTCAGTATCG'
        }
      ],
      panel_id: 1009998,
      panel_description:
        'This cocktail of 17 lyophilized and pre-titrated antibodies works in tandem with the Tapestri® scMRD AML DNA Panel, enabling the generation of both genotype and immunophenotype data relevant to acute myeloid leukemia (AML) measurable residual disease (MRD) research. This cocktail is available only as part of the Tapestri® Single-Cell MRD AML Multiomics Kit. Contact a Mission Bio representative for more information.',
      panel_label: 'totalseq-d-scMRD-AML-antibody-cocktail-v1',
      panel_name: 'TotalSeq™-D scMRD AML Antibody Cocktail v1',
      panel_size: 9999,
      panel_status: 'Ready',
      panel_type: 'Catalog',
      panel_source: 'Wet Lab-Tested',
      pct_coverage: 100,
      num_antibodies: 17,
      num_amplicons: 0,
      user_id: 1,
      num_orders: 0,
      num_lineitems: 9999,
      total_target_size: 9999,
      s3_results_dir: '',
      validated: true,
      genome_id: -1,
      review: true,
      virtual_panel: false,
      ready_to_order: true,
      download_location:
        'https://dl.missionbio.io/data/protein-panels/totalseq-d-scMRD-AML-antibody-cocktail-v1.csv'
    }
  ]
}

const mutations: MutationTree<PanelsState> = {
  DNA_PANELS_FETCH_SUCCESS(state, payload) {
    state.dnaPanels = payload.map((item: DNAPanel) => ({
      ...item,
      pct_coverage: Number(item.pct_coverage)
    }))
  },

  CUSTOM_GENOME_FETCH_SUCCESS(state, payload) {
    state.customGenomes = payload
  }
}

const actions: ActionTree<PanelsState, RootState> = {
  async getCatalogPanels(context) {
    const response = await getCatalogPanels()
    context.commit('DNA_PANELS_FETCH_SUCCESS', response.data)
  },

  async getUserPanels(context, { userId }) {
    const response = await getSummaryOfAllCustomPanelsOfUser({
      userId
    })
    context.commit('DNA_PANELS_FETCH_SUCCESS', response.data)
  },

  async getCustomGenomes(context) {
    const response = await getReferenceGenomes()
    context.commit('CUSTOM_GENOME_FETCH_SUCCESS', response.data.data)
  },

  async getAdminGenomesFiles(context) {
    const response = await getAdminGenomesFiles()
    context.commit('CUSTOM_GENOME_FETCH_SUCCESS', response.data.data)
  },

  async deleteCustomPanel(context, { panelId }) {
    const arrayData = context.state.dnaPanels
    const index = arrayData.findIndex((x: DNAPanel) => x.panel_id === panelId)
    await deletePanel({
      panelId
    })
    arrayData.splice(index, 1)
  },

  async deleteAdminPanel(context, { panelId }) {
    const arrayData = context.state.dnaPanels
    const index = arrayData.findIndex((x: DNAPanel) => x.panel_id === panelId)
    await deleteAdminPanel({
      panelId
    })
    arrayData.splice(index, 1)
  },

  async getAdminPanels(context) {
    const response = await getAdminPanels()
    context.commit('DNA_PANELS_FETCH_SUCCESS', response.data.data)
  }
}

const getters: GetterTree<PanelsState, RootState> = {
  dnaPanels(state): DNAPanel[] {
    return state.dnaPanels
  },

  dnaCustomPanels(getters): DNAPanel[] {
    return getters.dnaPanels.filter(
      x => x.panel_type?.toLocaleLowerCase() === 'custom' || 'whiteglove'
    )
  },

  dnaCatalogPanels(getters): DNAPanel[] {
    return getters.dnaPanels.filter(
      x => x.panel_type?.toLocaleLowerCase() === 'catalog'
    )
  },

  customGenomes(state): GenomeReferenceFiles {
    return state.customGenomes
  },

  proteinPanels(state): ProteinPanel[] {
    return state.proteinPanels
  },

  proteinCatalogPanels(state): ProteinPanel[] {
    return state.proteinPanels.filter(
      x => x.panel_type?.toLocaleLowerCase() === 'catalog'
    )
  }
}

export const panels: Module<PanelsState, RootState> = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
